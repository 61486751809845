html {
  height: 100%;
  font-size: 62.5%;
}

body {
  font-size: 137%;
  margin: auto;
  background-color: #ff91de;
  height: 95vh;
}

.introduction {
  text-align: center;
  font-size: 3rem;
  text-transform: uppercase;
  font-family: "Goldman", cursive;
}

#container {
  padding-top: 3%;
  text-align: center;
  font-size: 2rem;
}

.menu{
  padding-top: 1%;
  text-align: center;
}

.selection{
  font-size: 24px;
}

.copyright {
  position: absolute;
  width: 98.7%;
  height: 16px;
  bottom: 0%;
  text-align: center;
}

.pokemon {
  max-width: 100%;
  height:30%;
}
.identifier{
  size: 300%;
  font-size: 400%
};
#checkmark{
  color: lime;
}
#crossmark{
  color: red;
}

.search-pokedex{
  text-align: center;
  font-size: 2rem;
}

.search{
  margin: auto;
}

.pokedex-entry{
  margin-top: 5%;
}

.pokemon-name{
  padding-top: 100%;
}

.page{
  text-align: center;
}

.pokedex-pokemon{
  height: 40%;
}

.pokedex-name{
  text-transform: Capitalize;
}

.row1{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.row2{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.row3{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
#loading{
  color: white;
  font-size: 700%;
  position: fixed;
  height: 100%;
  width: 100%;
  text-align: center;
}